<template>
  <div class="CurentMessageDetail">
    <div class="leftConent">
      <h1>当前位置</h1>
      <div class="leftCon">
        <h2>{{ list.infoDetail.title }}</h2>
        <div class="leftCon_msg">
          <span
            ><strong class="el-icon-time"></strong>{{ list.infoDetail.beginDate }}</span
          >
          <span
            ><strong class="el-icon-view"></strong
            ><strong>{{
              list.infoDetail.pviews == -1 ? "222" : list.infoDetail.pviews
            }}</strong
            >人阅读</span
          >
        </div>
        <div class="detailBox" v-html="list.infoDetail.content"></div>
      </div>
    </div>
    <div class="rightConent">
      <h1>平台公告</h1>
      <div class="rightCon">
        <div class="PlatformNotice">
          <div
            class="NoticeBox"
            v-for="(item, index) in list.NoticeList"
            :key="index"
              @click="jumpInfoDetail(item.id)"
          >
            <h2>{{ item.title }}</h2>
            <p>{{ item.description }}</p>
            <div class="NoticeBox_bottom">
              <span><strong class="el-icon-time"></strong>{{ item.beginDate }}</span>
              <span
                ><strong class="el-icon-view"></strong
                ><strong>{{ item.pviews == -1 ? "222" : item.pviews }}</strong
                >人阅读</span
              >
            </div>
            <el-divider></el-divider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.clear {
  clear: both;
}
  h1 {
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
.CurentMessageDetail {
  margin: 20px 0;
  float: left;
  width: 100%;
  .leftConent {
    width: 64%;
    margin-right: 15px;
    float: left;
    .leftCon {
      padding: 30px;
      background: #ffffff;
      h2 {
        color: #333333;
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
      }
      .leftCon_msg {
        margin-bottom: 10px;
        height: 30px;
        line-height: 30px;
        color: #999999;
           font-size: 12px;
        span {
          padding-right: 10px;
          i {
            padding-right: 7px;
          }
        }
      }
      .detailBox {
        p {
          font-size: 13px;
          color: #999999;
          line-height: 30px!important;
        }
      }
    }
  }
  .rightConent {
    width: calc(36% - 15px);
    float: left;
    .rightCon {
      padding: 30px;
      background: #ffffff;
      .PlatformNotice {
        .NoticeBox {
          h2 {
            color: #333333;
            font-size: 16px;
            font-weight: bold;
            line-height: 30px;
          }
          p {
            font-size: 13px;
            color: #999999;
          }
          .NoticeBox_bottom {
               font-size: 12px;
            margin-top: 30px;
            height: 30px;
            line-height: 30px;
            color: #999999;
            span {
              padding-right: 10px;
              i {
                padding-right: 7px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import { articleList, getAritcledDetail } from "api/product.js";

export default {
  name: "CurrentMessage",
  data() {
    return {
      list: {
        NoticeList: [],
        infoDetail: "",
      },
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    jumpInfoDetail(infoId) {
       // 文章详情
      getAritcledDetail(infoId).then((res) => {
        this.$set(this.list, "infoDetail", res.data.data);
      });
    },
    getInfo() {
      const parmes = sessionStorage.getItem("infoId");
      // 文章详情
      getAritcledDetail(parmes).then((res) => {
        this.$set(this.list, "infoDetail", res.data.data);
      });

      const noticParams = {
        type: 0,
        current: 1, //	当前页
        size: 10, //	每页的数量
      };
      // 公告列表
      articleList(noticParams).then((res) => {
        const noticeArray = res.data.data.records;
        this.$set(this.list, "NoticeList", noticeArray);
      });
    },
  },
  created() {},
};
</script>
